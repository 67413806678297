/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import PageTemplate from "../templates/page"
import SEO from "../components/seo"
import Link from "../components/link"
import Container from "../components/container"
import ChevronIcon from "../images/sha-arrow.svg"
import thumbnail1 from "../images/thumbnail1.jpg"
import thumbnail2 from "../images/thumbnail2.jpg"
import thumbnail3 from "../images/thumbnail3.jpg"
import thumbnail4 from "../images/thumbnail4.jpg"
import thumbnail5 from "../images/thumbnail5.jpg"
import thumbnail6 from "../images/thumbnail6.jpg"

const IndexPage = ({ ...restProps }) => {
  const thumbnails = {
    thumbnail1,
    thumbnail2,
    thumbnail3,
    thumbnail4,
    thumbnail5,
    thumbnail6,
  }
  const { site } = useStaticQuery(graphql`
    {
      site: contentfulMinisite(slug: { eq: "sha" }) {
        heroBackground {
          file {
            url
          }
        }
        heroShaLogo {
          file {
            url
          }
        }
        metaDescription {
          metaDescription
        }
        propaneLogo {
          file {
            url
          }
        }
        siteTitle
        jsonData {
          jsonData
        }
      }
    }
  `)
  const data = site?.jsonData?.jsonData && JSON.parse(site.jsonData.jsonData)
  const renderCard = ({ vimeo, tag, title, text, thumbnail }, type) => (
    <Link
      sx={{ color: "inherit", ":hover": { color: "inherit" } }}
      link={vimeo}
    >
      <div
        sx={{
          height: "100%",
          background: "white",
          p: "20px",
          borderRadius: "10px",
          border: "1px solid #e5e3e4",
          display: "flex",
          flexDirection: type === "primary" ? "row" : "column",
          alignItems: type === "primary" ? "center" : "flex-end",
          transition: "0.2s ease",
          "&:hover": { boxShadow: "0 0 11px rgba(33,33,33,.2)" },
        }}
      >
        <img src={thumbnails[thumbnail]} sx={{ mb: "10px" }} />
        <div sx={{ flexGrow: 1 }}>
          {tag && <div sx={{ color: "#a1a1a1", mb: "16px" }}>{tag}</div>}
          {title && (
            <div
              sx={{
                fontSize: type === "primary" ? "22px" : "24px",
                mb: type === "primary" ? "6px" : "16px",
              }}
            >
              {title}
            </div>
          )}
          {text && <div>{text}</div>}
        </div>
        <div sx={{ ml: "30px" }}>
          <img
            src={ChevronIcon}
            alt="chevron"
            sx={{ width: "10px", minWidth: "10px", fill: "#00a49a" }}
          />
        </div>
      </div>
    </Link>
  )
  return (
    <React.Fragment>
      <SEO
        title={site?.siteTitle}
        description={site?.description?.description}
      />
      <Container>
        <div
          sx={{
            display: "grid",
            gridTemplateColumns: ["1fr", "1fr", "1fr 1fr"],
            gap: "10px",
            mb: "30px",
          }}
        >
          {data.topRow.map(item => renderCard(item, "primary"))}
        </div>
        <div sx={{ fontWeight: 600, mb: "10px", mx: "10px" }}>
          {data.bottomRowTitle}
        </div>
        <div
          sx={{
            display: "grid",
            gridTemplateColumns: [
              "1fr",
              "1fr 1fr",
              "1fr 1fr 1fr",
              "1fr 1fr 1fr",
            ],
            gap: "10px",
          }}
        >
          {data.bottomRow.map(item => renderCard(item, "secondary"))}
        </div>
      </Container>
    </React.Fragment>
  )
}

export default IndexPage
